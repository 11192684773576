<template>
   <div class="error">
      <h1 class="error__title">{{ $t('404Page.errorTitle') }}</h1>
      <p class="error__text">{{ $t('404Page.errorMessage') }}</p>
      <router-link class="error__link" :to="{ name: 'advice' }">
         <SWButton  class="button_back">{{ $t('404Page.btnText') }}</SWButton>
      </router-link>
   </div>
</template>

<script>
import { SWButton } from 'sw-ui-kit';
export default {
   components: {
      SWButton
   },
};
</script>

<style lang="scss" scoped>
.error{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100vh;
   font-family: 'Roboto, sans-serif';
}
.error__title{

   margin-bottom: 20px;
   font-weight: 600;
   font-size: 35px;
}
.error__text{

   margin-bottom: 40px;
   font-weight: 400;
   font-size: 20px;
}
.button_back{
   font-size: 18px!important;
   padding: 20px!important;
}
</style>